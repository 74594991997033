<script lang="ts">
import { type InputUIProps } from "./types";
import UploadIcon from "~icons/ph/upload";
import Plus from "~icons/ph/plus-circle";
import { plural } from "../../lib/formatting";
import dragDrop from "../../lib/dragDrop";

let {
  accept, required, multiple, error, hasFiles, addFiles, children
} : InputUIProps = $props();

function fileInputChange(e: Event) {
  const input = e.target as HTMLInputElement
  if(input.files?.length){
    addFiles(input.files);
    input.files = null
  }
}
</script>
<div>
  <div class:visual-container={hasFiles} class:list-container={hasFiles}>
    {@render children()}
    {#if multiple || !hasFiles}
      <label
        use:dragDrop={addFiles}
        class:visual-container={!hasFiles}
        class:as-container={!hasFiles}
        class:as-button={hasFiles}
        class="input cursor-pointer relative items-center drag-target"
        class:input-error={error}
        class:border-gray-400={!error}
      >
        {#if hasFiles}
          <Plus class="block w-5 h-5" />
        {:else}
          <UploadIcon class="block w-10 h-10" />
        {/if}
        <span class:text-sm={hasFiles} class="sm:hidden">Tap to {hasFiles ? "add more" : "choose"}{" "}{multiple ? "files" : "a file"}</span>
        <span class:text-sm={hasFiles} class="hidden sm:inline">Drag and drop or click to {hasFiles ? "add more" : "upload"}{" "}{multiple ? "files" : "a file"}</span>
        <input
          type="file"
          onchange={fileInputChange}
          class="sr-only"
          required={(required && !hasFiles) || undefined}
          multiple={multiple || undefined}
          accept=".{accept.join(",.")}"
        />
      </label>
    {/if}
  </div>
  <div class="text-sm mt-1 py-1 px-1 -mb-1 rounded-md transition-colors" class:bg-error={error} class:text-error-content={error} class:text-gray-600={!error}>
    {#if error && error.type == "size"}
      File too large! Maximum {error.detail}
    {:else if error && error.type == "count"}
      Too many files! Maximum {error.detail}
    {:else}
      Allowed file {plural(accept.length, "type")}: {accept.join(", ").toLowerCase()}
    {/if}
  </div>
</div>
<style lang="postcss">
  .visual-container {
    min-height: 6.5rem;
    @apply w-full bg-white border-solid border rounded-md flex flex-col;
  }
  .list-container {
    @apply border-gray-400 py-2 px-2 gap-1;
  }
  .as-container {
    @apply justify-center p-3 font-medium;
  }
  .as-button {
    @apply btn font-medium flex justify-start gap-2 min-h-8 h-8 mt-1.5;
  }
  .drag-target {
    @apply  data-[drag-target]:outline-dashed
            data-[drag-target]:outline-2
            data-[drag-target]:outline-offset-2
            data-[drag-target]:outline-info;
  }
</style>