<script lang="ts">
import { Phase, type ProgressUIProps } from "./types";
import Retry from "~icons/ph/arrow-clockwise";
import Cancel from "~icons/ph/x-circle";
import Delete from "~icons/ph/trash";
import { slide } from "svelte/transition";
let {
  filename,
  class: className,
  progress, // 0-100 | null | undefined
  msecRemaining,
  phase,
  ...fns // {cancel?, delete?, retry?}
} : ProgressUIProps = $props()

let                   [label,         info,  success, error, override ] = $derived({
  [Phase.WAITING]:    ["Waiting…",    true,  false,   false, 0        ] as const,
  [Phase.PREPARING]:  ["Preparing…",  true,  false,   false, undefined] as const,
  [Phase.UPLOADING]:  ["Uploading…",  true,  false,   false, false    ] as const,
  [Phase.FINALIZING]: ["Finalizing…", true,  false,   false, undefined] as const,
  [Phase.COMPLETE]:   ["Ready",       false, true,    false, 100      ] as const,
  [Phase.CANCELING]:  ["Canceling…",  false, false,   true,  undefined] as const,
  [Phase.DELETING]:   ["Deleting…",   false, false,   true,  undefined] as const,
  [Phase.FAILED]:     ["Failed",      false, false,   true,  100      ] as const,
}[phase])
let progressElement = $state<HTMLProgressElement>()
$effect(()=>{
  // Originally <progress ... value={override===false ? progress : override}>, but bug(?) in Svelte
  // seems to mean that undefined doesn't cause the attribute to be removed if it was previously
  // set, instead raises TypeError: The provided value is non-finite. null and false are coerced to
  // zero instead of removing the attribute.
  if(override === undefined) progressElement.removeAttribute("value");
  else if(override === false) progressElement.setAttribute("value", `${progress}`);
  else progressElement.setAttribute("value", `${override}`);
})

let actionButtons = $derived([
  {onclick: fns.retry,  Icon: Retry,  label: "Retry"},
  {onclick: fns.cancel, Icon: Cancel, label: "Cancel"},
  {onclick: fns.delete, Icon: Delete, label: "Delete"},
].filter(a => a.onclick))

// Prevent form submission when busy via setCustomValidity
// <button type="submit"> is the safest element where validity works as we need
let button = $state<HTMLButtonElement>();
$effect(()=>{
  switch(phase) {
    case Phase.FAILED:
      // Iff cancel and retry are available, FAILED blocks form submission. Otherwise,
      // user doesn't have good options and shouldn't be hassled with a non-choice.
      return button.setCustomValidity((fns.cancel && fns.retry) ? "Please cancel or retry" : "");
    case Phase.COMPLETE:
      // COMPLETE means no error: congrats!
      return button.setCustomValidity("");
    default:
      // All other states indicate action in progress, block form submission
      return button.setCustomValidity("Please wait");
  }
})

const formatDuration = (msec: number) => msec > 90_000 ? `${Math.ceil(msec / 60_000)} min` : `${Math.ceil(msec / 1_000)} sec`

</script>
<div class="file-progress items-center h-11 w-full {className}" transition:slide>
  <div class="filename-area text-base min-w-0 whitespace-nowrap overflow-x-hidden text-ellipsis">{filename}</div>
  <button bind:this={button} onclick={e=>e.preventDefault()} class="progress-area pointer-events-none">
    <label class="relative block">
      <progress class="progress w-full h-5 block rounded-md border border-solid"
        class:progress-info={info} class:border-info={info}
        class:progress-success={success} class:border-success={success}
        class:progress-error={error} class:border-error={error}
        max="100"
        bind:this={progressElement}
      ></progress>
      <div class="absolute left-0 top-0 w-full h-full p-[1px] flex items-stretch justify-center text-xs font-medium">
        <span class="rounded bg-white bg-opacity-70 px-3 flex items-center">{label}{(msecRemaining && override === false) ? ` ${formatDuration(msecRemaining)} left` : ""}</span>
      </div>
    </label>
  </button>
  <div class="action-area gap-1 pl-1 flex justify-end h-6">
    {#each actionButtons as {onclick, Icon, label}}
      <button {onclick} type="button" class="btn btn-xs btn-circle btn-ghost tooltip" data-tip={label}>
        <Icon class="mx-auto" /><span class="sr-only">{label}</span>
      </button>
    {/each}
  </div>
</div>
<style lang="postcss">
  .file-progress {
    display: grid;
    grid-template-areas: "filename action"
                         "progress progress";
    grid-template-columns: repeat(2, minmax(0, auto));
    grid-template-rows: 1fr 1fr;
  }
  .progress-area {
    grid-area: progress;
  }
  .action-area {
    grid-area: action;
  }
  .filename-area {
    grid-area: filename;
  }
</style>
