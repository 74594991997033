/*
 * Intended to be used as an "action" on an HTML element in a Svelte component.
 * - Calls the function passed to it when file(s) are dropped in the browser
 *   window & closer to the element than to any other element with use:dragDrop.
 * - Sets the data-drag-target attribute of the element when a drag is in
 *   progress and element is the closest target. E.g.:
 * 
 *   <div use:dragDrop={myHandleFiles} class="data-[drag-target]:bg-red"></div>
 */
const elements = new Map<HTMLElement, OnFilesDrop>()
let wasEmpty = true
function add(element: HTMLElement, onFilesDrop: OnFilesDrop | false | null){
  if(!onFilesDrop) return remove(element);
  elements.set(element, onFilesDrop);
  if(wasEmpty) {
    window.addEventListener('dragover', onDragover, {capture: true});
    window.addEventListener('dragleave', onDragleave, {capture: true});
    window.addEventListener('drop', onDrop, {capture: true});
    wasEmpty = false;
  }
}
function remove(element){
  elements.delete(element);
  if(!wasEmpty && elements.size === 0) {
    window.removeEventListener('dragover', onDragover, {capture: true});
    window.removeEventListener('dragleave', onDragleave, {capture: true});
    window.removeEventListener('drop', onDrop, {capture: true});
    wasEmpty = true;
  }
}

function nearestElement(clientX: number, clientY: number) {
  if(elements.size == 1) for(const el of elements.keys()) return el;
  let nearest = null, minDistanceSq = Infinity;
  for(const el of elements.keys()) {
    const {top, right, bottom, left} = el.getBoundingClientRect();
    const x = (right + left) / 2, y = (top + bottom) /2;
    const distanceSq = Math.pow(clientX - x, 2) + Math.pow(clientY - y, 2);
    if(distanceSq < minDistanceSq) {
      nearest = el;
      minDistanceSq = distanceSq;
    }
  }
  return nearest;
}

let wasNearest = null
function onDragover(event: DragEvent){
  event.preventDefault();
  const nearest = nearestElement(event.clientX, event.clientY);
  if(nearest != wasNearest) {
    if(wasNearest) wasNearest.removeAttribute("data-drag-target");
    nearest.setAttribute("data-drag-target", "");
    wasNearest = nearest;
  }
}

function onDragleave(event: DragEvent){
  event.preventDefault()
  if (!event.relatedTarget || (event.relatedTarget === document.documentElement)) {
    clear();
  }
}

function onDrop(event: DragEvent){
  event.preventDefault()
  clear();
  const nearest = nearestElement(event.clientX, event.clientY);
  if(!nearest || !event.dataTransfer.files) return;
  elements.get(nearest)(event.dataTransfer.files);
}

function clear(){
  if(wasNearest) wasNearest.removeAttribute("data-drag-target");
  wasNearest = null;
}


export default function dragDrop(element: HTMLElement, onDrop: OnFilesDrop | null | false){
  add(element, onDrop);
  return {
    update(onDrop: OnFilesDrop | null | false) {
      add(element, onDrop);
    },
    destroy() {
      remove(element);
    }
  }
}

type OnFilesDrop = (files: FileList)=>any