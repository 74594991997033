import type { Snippet } from "svelte";
import type { EventHandler } from "svelte/elements";

// Phase of ProgressUI and (mostly) SharepointUpload
export const Phase = {
  WAITING: Symbol("WAITING"),
  PREPARING: Symbol("PREPARING"),
  UPLOADING: Symbol("UPLOADING"),
  FINALIZING: Symbol("FINALIZING"),
  COMPLETE: Symbol("COMPLETE"),
  CANCELING: Symbol("CANCELING"),
  DELETING: Symbol("DELETING"),
  FAILED: Symbol("FAILED")
} as const

export type PhaseValue = typeof Phase[keyof typeof Phase];

// Props for SharepointFileInput, the one component here you're likely to mount
// via svelte_component or import in your own components.
export type SharepointFileInputProps = {
  name: string;
  accept: string[];
  createUploadSessionUrl: string;
  required?: boolean;
  multiple?: boolean;
}

export type ProgressUIProps = {
  filename: string;
  phase: PhaseValue;
  progress?: number;
  msecRemaining?: number;
  class?: string;
  cancel?: EventHandler;
  delete?: EventHandler;
  retry?: EventHandler;
}

export type InputUIProps = {
  accept: string[];
  required?: boolean;
  multiple?: boolean;
  error?: InputUIError;
  hasFiles?: boolean;
  addFiles: (list: FileList)=>void;
  children?: Snippet;
}

export type InputUIError = {
  type: "count"|"type"|"size";
  message?: string;
  detail?: any;
} | false

// Constructor args for SharepointUpload
export type SharepointUploadInit = {
  file: File;
  name: string;
  // Optional, abscence prevents .start() from being called automatically.
  // Meant to facilitate code tests, but I have not written then as yet.
  createUploadSessionUrl?: string;
}

// Structure expected in response from createUploadSessionUrl endpoints.
// All such should use Sharepoint::DirectUpload.create_session_for_attachment
// and trivially process the result as in TasksController#create_upload_session.
export type UploadSession = {
  upload_url: string;
  complete_url: string;
}

// Structure expected in response from UploadSession.complete_url endpoints.
export type FinalizedUpload = {
  blob_id: string;
  delete_url: string;
}
